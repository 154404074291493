import axios from "../axios";
import { getSubscriptionsCount, getSubscriptions } from "../../config";
import { createAsyncThunk } from "@reduxjs/toolkit";


export const GetSubscriptionsCount = createAsyncThunk(
  `subscriptionscount/count`,
  async () => {
    const response = await axios.get(getSubscriptionsCount);
    // localStorage.setItem("count", response?.data?.totalUsers);

    return await response?.data;
  }
);
