import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSubscriptions } from "../../config";
import axios from "../axios";


export const GetSubscriptions = createAsyncThunk(
  `/subscriptions`,
  async (pagination) => {
    const response = await axios.get(`${getSubscriptions}${pagination}`);
    //   console.log(response?.data);
    return await response?.data;
  }
);
