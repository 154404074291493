import { createSlice } from "@reduxjs/toolkit";
import { GetSubscriptionsCount } from "../async-actions/SubscriptionsCount";

const initialState = {
  status: "",
  count: [],
};

const SubscriptionsCountSlice = createSlice({
  name: "subscriptionscount",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetSubscriptionsCount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.count = action.payload;
      state.status = action.payload.status;
    });
  },
});

export default SubscriptionsCountSlice.reducer;
