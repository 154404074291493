import { createSlice } from "@reduxjs/toolkit";
import { GetActiveSubscriptions } from "../async-actions/ActiveSubscriptions";

const initialState = {
  status: "",
  isLoading:false,
  activesubscriptions: [],
};

const ActiveSubscriptionsSlice = createSlice({
  name: "recentalerts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(GetActiveSubscriptions.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(GetActiveSubscriptions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.activesubscriptions = action.payload;
      state.status = action.payload.status;
    });
  },
});

export default ActiveSubscriptionsSlice.reducer;
