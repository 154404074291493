import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAlertsCount } from "../../config";
import axios from "../axios";


export const GetAlertsCount = createAsyncThunk(
  `/alertscount`,
  async () => {
    const response = await axios.get(getAlertsCount);
    //   console.log(response?.data);
    return await response?.data;
  }
);
