import { createSlice } from "@reduxjs/toolkit";
import { GetDashboardDetails } from "../async-actions/Dashboard";

const initialState = {
  status: "",
  isLoading:false,
  details: [],
};

const DashboardSlice = createSlice({
  name: "dashboarddetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(GetDashboardDetails.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(GetDashboardDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.details = action.payload;
      state.status = action.payload.status;
    });
  },
});

export default DashboardSlice.reducer;
