import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateUserById } from "../../config";

// Create the async thunk
export const updateUser = createAsyncThunk(
  "updateuser",
  async (changedData, thunkAPI) => {
    // Perform your login logic here (e.g., API call)
    const response = await fetch(updateUserById, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,

      },
      body: JSON.stringify(changedData),
    });
    const data = await response.json();
    console.log(data);
    if (!response.ok) {
      // Handle error case

      return thunkAPI.rejectWithValue(data);
    }

    // Return the data received from the server
    return data;
  }
);
