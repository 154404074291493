import React, { useCallback, useEffect, useState } from 'react';
import "../Change_Password/change_password.css";
import { useDispatch, useSelector } from 'react-redux';
import { changepassword } from '../../redux/async-actions/ChangePassword';
import { GetDashboardDetails } from '../../redux/async-actions/Dashboard';

export default function Change_Password() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [email, setEmail] = useState("");
  const passwordchange = useSelector((state) => state.changepasswords);
  const [userErrorMessage, setUserErrorMessage] = useState("");
  const [errorMessage,setErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [successMessage, setSuccessMessage] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");


  const dispatch = useDispatch();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setUserErrorMessage("");
    setPasswordErrorMessage("");

    setSuccessMessage("");

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      const passwords = {
        email: email,
        currentPassword: currentPassword,
        newPassword: newPassword,
      };

      await dispatch(changepassword(passwords));
      
    } catch (error) {
      // Error occurred during password change
      
    }

    
    
    
   
    
    // setTimeout(() => {
    //   setErrorMessage("");
    //   setSuccessMessage("");
    // }, 5000);
  }, [newPassword, confirmPassword, email, currentPassword, dispatch]);
  
  useEffect(() => {
    if (passwordchange?.data) {
      if (passwordchange.data.message === "Password changed successfully") {
        // Password change successful
        // console.log(passwordchange.data.message);
        // console.log("success");
        setSuccessMessage(passwordchange.data.message);
      } else if (passwordchange.data.message === "Invalid current password") {
        // Password change failed
        // console.log(passwordchange.data.message);
        // console.log("password wrong");
        setPasswordErrorMessage(passwordchange.data.message);
      } else {
        setUserErrorMessage(passwordchange.data.message);
        // console.log("user wrong");
        // console.log(passwordchange.data.message);
      }
    }
    dispatch(GetDashboardDetails());

  }, [dispatch, passwordchange.data]);

  return (
    <div className='container-1'>
      <h3>Change Password</h3>
      <form onSubmit={handleSubmit} className='change-password'>
        <div>
          <label>Current Email Id:</label>
          <input
          className='change-password'
            type="email"
            value={email}
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
            required
          />
                  {userErrorMessage && <p className='err-msg'>{userErrorMessage}</p>}

        </div>

        <div>
          <label>Current Password:</label>
          <input
            type="text"
            className='change-password'

            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
                  {passwordErrorMessage && <p className='err-msg'>{passwordErrorMessage}</p>}

        </div>

        <div>
          <label>New Password:</label>
          <input
                    className='change-password'

            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Confirm Password:</label>
          <input
            type="password"
            className='change-password'

            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
                  {errorMessage && <p className='err-msg'>{errorMessage}</p>}

        </div>
        <div>{successMessage && <p  className='success-msg'>{successMessage}</p>}</div>
        <button type="submit">Change Password</button>
      </form>
    </div>
  );
}
