import { createSlice } from "@reduxjs/toolkit";

import { GetUserSearch } from "../async-actions/UserSearch";

const initialState = {
  status: "",
  isLoading:false,
  usersearchdata: [],
};

const UserSearchSlice = createSlice({
  name: "usersearch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(GetUserSearch.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(GetUserSearch.fulfilled, (state, action) => {
      state.isLoading = false;
      state.usersearchdata = action.payload;
      // console.log(action.payload);
      state.status = action.payload.status;
    });
  },
});

export default UserSearchSlice.reducer;
