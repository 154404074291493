import React, { useEffect, useReducer, useState } from "react";
import "./user_alert_details.css";
// import { Search } from "semantic-ui-react";
import DownloadIcon from "../image/download_icon.svg";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import { DataGrid, gridClasses } from "@mui/x-data-grid";
// import { alpha, styled } from "@mui/material/styles";
import { IoMdSearch } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { GetAlerts } from "../../redux/async-actions/Alert";
import TableList from "../../component/TableList";
import Pagination from "../../component/Pagination";
import { UsersAlertTable, Users_alert_data } from "./data";
import Loader from "../../Layout/Loader/Loader";
import { GetAlertSearch } from "../../redux/async-actions/AlertSearch";
import FileSaver from "file-saver";
import { BASE_URL } from "../../config/index.js";

export default function User_Alert_History() {
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [swap, setswap] = useState(false);
  const [pagination1, setpagination1] = useState(1);
  const isLoading = useSelector((state) => state?.alerts?.isLoading)
  const Alerts = useSelector((state) => state?.alerts?.alertsdata);
  const AlertSearch = useSelector((state) => state?.alertsearch?.alertsearchdata);
  // console.log(AlertSearch);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [reducerValue1, forceUpdate1] = useReducer((x) => x + 1, 0);


  const [pagination, setpagination] = useState(
    Alerts?.alersdata?.users?.page || 1
  );
  const dispatch = useDispatch();
  const onclickdownload = () => {
    let link = `${BASE_URL}/export_Alert`
    FileSaver.saveAs(link, 'alerts');

  }
  const handleSortChange = (event) => {
    const selectedValue = event.target.value;

    setSortValue(selectedValue);
  };
  const handleFilterChange = (event) => {
    const selectedValue = event.target.value;

    setFilterValue(selectedValue);
  };

  useEffect(() => {
    var pag = `?page=${pagination}`;
    dispatch(GetAlerts(pag));

  }, [dispatch, pagination]);
  useEffect(() => {
    var key = `?keyword=${searchValue}`;
    dispatch(GetAlertSearch(key));
  }, [reducerValue]);
  useEffect(() => {
    var key = `?page=${pagination}${sortValue !== "default" ? `&orderBy=${sortValue}` : ""
      }${filterValue !== "default" ? `&sortBy=${filterValue}` : ""}`;

    dispatch(GetAlertSearch(key));
  }, [reducerValue1, pagination]);
  useEffect(() => {
    if (searchValue) {
      setTimeout(() => {

        forceUpdate();
      }, 300);


    } else {
      setFilteredRows(Alerts?.results);
      // console.log(filteredRows);
    }
  }, [searchValue]);
  useEffect(() => {
    // Filter rows based on search value
    if (sortValue || filterValue) {
      setTimeout(() => {
        forceUpdate1();
        // console.log("forced");
      }, 300);
    } else {
      setFilteredRows(Alerts?.results);
    }
  }, [sortValue, filterValue]);
  useEffect(() => {
    setTimeout(() => {
      setFilteredRows(AlertSearch?.results);
    }, 100);
  }, [AlertSearch?.results]);

  return (
    <>
      {isLoading ? <Loader /> :
        <div className="container-1">
          <div>
            <h2>User's Alert History</h2>
          </div>
          <div className="searchbox">
            <div className="filterview1">
              {/* <Search
            className="search"
            size="big"
            //loading={loading}
            placeholder="Search..."
            //   onResultSelect={(e, data) =>
            //   //  dispatch({ type: "UPDATE_SELECTION", selection: data.result.title })
            //   }
            // onSearchChange={handleSearchChange}
            //  results={results}
            //  value={value}
          /> */}
              <input
                type="text"
                placeholder="Search by Keywords"
                name="search"
                className="search"
                value={searchValue}
                autoComplete="off"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <IoMdSearch
                className="icon-search"
                size={35}
                style={{ fill: "#2b3b41", backgroundColor: "#fff" }}
              />
            </div>
            {/* <div className="filterview">
          <div className="button-border">
            <FilterAltIcon sx={{ color: "#AF1E3E" }} />
            <div>Filter</div>
          </div>

          <select className="selectview">
            <option value="fruit">Sort by</option>

            <option value="vegetable">A to Z</option>

            <option value="meat">Z to A</option>
          </select>
          <DownloadIcon sx={{ color: "#AF1E3E", fontSize: 30 }} />
        </div> */}
            <div className="filter-set">
              <div className="filter-by">
                <div style={{ whiteSpace: "nowrap" }}>Filter by</div>
                <div>
                  <select
                    className="selectview"
                    onChange={handleFilterChange}
                    value={filterValue}
                  >
                    <option selected value="default">
                      Default
                    </option>
                    <option value="name">Firstname</option>
                    <option value="lastName">LastName</option>
                  </select>
                </div>
              </div>
              <div className="sort-by">
                <div style={{ whiteSpace: "nowrap" }}>sort by</div>
                <div>
                  <select
                    className="selectview"
                    onChange={handleSortChange}
                    value={sortValue}
                  >
                    <option selected value="default">
                      Default
                    </option>
                    <option value="asc">A to Z</option>
                    <option value="desc">Z to A</option>
                  </select>
                </div>
              </div>{" "}
            </div>
            <div>

              <button className="download-button" onClick={onclickdownload}
              >
                <img src={DownloadIcon} alt="download-icon" />
              </button>
            </div>
          </div>
          <div className="listview">
            <TableList
              Head={UsersAlertTable}
              list={filteredRows}
              datas={Users_alert_data}
              pagination={swap ? pagination1 : pagination}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {!searchValue && (
                <p style={{ float: "right", margin: "15px 50px 0 20px" }}>Alerts Count: {Alerts?.totalUsers
                }</p>)}

              {!searchValue && (

                <Pagination
                  setpagination={swap ? setpagination1 : setpagination}
                  pagination={swap ? pagination1 : pagination}
                  total={Alerts?.totalPages}
                  data={Alerts}
                />
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
}
