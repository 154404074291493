import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRecentAlerts } from "../../config";
import axios from "../axios";


export const GetRecentAlerts = createAsyncThunk(
  `/recentalerts`,
  async () => {
    const response = await axios.get(getRecentAlerts);
    //   console.log(response?.data);
    return await response?.data;
  }
);
