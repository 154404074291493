import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAlert } from "../redux/async-actions/UpdateAlert";
import { useEffect } from "react";
import { GetDashboardDetails } from "../redux/async-actions/Dashboard";
import { useState } from "react";
import { GetRecentAlerts } from "../redux/async-actions/RecentAlerts";
import { useReducer } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D9D9D980",
    color: "#2B3B41",
    fontFamily: "Roboto Condensed",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Roboto Condensed",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",

    borderBottom: "4px solid black", // Add black color bottom border
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#f9f9f9",
  },
}));

function formatDateFromTimestamp(timestamp) {
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

const StyledTable = styled(Table)(({ theme }) => ({
  borderCollapse: "separate",
  borderSpacing: "0 8px", // Add space between rows (change the value as needed)
}));
function DashboardTableList({
  Head,
  list,
  pagination,
  handleOpen,
  datas,
  handleOpen1,
  access,
}) {
  const dispatch = useDispatch();
  const [recentalertlist, setRecentAlertList] = useState( list );

  const RecentAlerts = useSelector((state) => state?.recentalerts?.recentalerts);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

const handleToggle = useCallback(async(a) => {
  const userId = a?.createdBy;
  const alarmId = a?._id;
  const ids ={
    "userId" : userId,
    "alarmId" : alarmId
  }
  try {
    await dispatch(updateAlert(ids));
    forceUpdate();

  } catch (error) {
    // Error occurred during password change
  }
  // setTimeout(() => {
  //   dispatch(GetDashboardDetails());
  // }, 1500);

},[]);
useEffect(() => {
  // Fetch recent alerts
  dispatch(GetRecentAlerts());

 
 
}, [ reducerValue]);
useEffect(()=>{
  if (!RecentAlerts?.loading) {
    setRecentAlertList(RecentAlerts?.matchingDocuments);
  }
},[RecentAlerts])

  return (
    <TableContainer component={Paper}>
      <StyledTable sx={{ maxWidth: 1480, }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {Head.map((a, i) => {
              return <StyledTableCell key={i}>{a.head}</StyledTableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {recentalertlist &&
            recentalertlist.map((a, i) => {
              return (
                <StyledTableRow
                  key={i}
                  style={{ outline: "1px solid #0000000D " }}
                >
                  <StyledTableCell component="th" scope="row">
                    {(pagination - 1) * 10 + i + 1}
                  </StyledTableCell>
                  {datas.map((b, j) => (
  <StyledTableCell key={j}>
    {b.name === "joinedData"
      ? b.value2 === "lastName"
        ? a?.joinedData?.[0]?.[b.value] === undefined
          ? ""
          : a?.joinedData?.[0]?.[b.value] +
            " " +
            a?.joinedData?.[0]?.[b.value2]
        : a?.joinedData?.[0]?.[b.value]
      :
      b.name === "edituser"?  <Link to={`64ed855fc5847bc5b9938cc5/view`}className="pink me-2">
      <MdEdit />
      </Link> :
      b.name==="toggle" ? 
      <>
                      <label className="switch">

      <input
      type="checkbox"
       checked={a[b.value]==="alert"?1:0} 
      onChange={() => handleToggle(a)} 
    />               <span className="slider round"></span>
    </label>
    </>
      :
        typeof a[b.name] === "boolean"
      ? b.name === "isActive"
      ? a[b.name]===true
        ? "Active"
        : "Inactive"
      :
      b.name === "isCancelled"
      ? a[b.name]===true
        ? "Cancelled"
        : "Active"
        :
      a[b.name].toString()
      : a[b.name] === undefined
      ? ""
      : b.name === "purchasedDateMs"
      ? formatDateFromTimestamp(a[b.name])
      :  a[b.name]}
  </StyledTableCell>
))}


                  {/* <StyledTableCell>{a._id}</StyledTableCell>

                  <StyledTableCell>{a.environment}</StyledTableCell>
                  <StyledTableCell>{a.productId}</StyledTableCell>
                  <StyledTableCell>{a.platform}</StyledTableCell>
                  <StyledTableCell>{a.purchasedDateMs}</StyledTableCell>
                  <StyledTableCell>{a.isCancelled.toString()}</StyledTableCell> */}
                </StyledTableRow>
              );
            })}
        </TableBody>
      </StyledTable>
      {recentalertlist?.loading && !list?.campaignlist?.campaigns?.data && (
        <div className="d-flex justify-content-center"></div>
      )}
      {recentalertlist?.campaignlist?.campaigns?.data?.length === 0 && (
        <div className="d-flex justify-content-center">
          <i>No results found!</i>
        </div>
      )}
    </TableContainer>
  );
}
export default React.memo(DashboardTableList);
