import { createSlice } from "@reduxjs/toolkit";
import { updateAlert } from "../async-actions/UpdateAlert";

export const UpdateAlertSlice = createSlice({
  name: "updatealert",
  initialState: {
    data: {},
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateAlert.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAlert.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        // localStorage.setItem("userToken", payload.token);
        state.data = payload;
      })
      .addCase(updateAlert.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        // console.log("rejected");
        state.errorMessage = payload;
        // console.log(payload.message);
      })
     
  },
});

export default UpdateAlertSlice.reducer;
