import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { RiArrowLeftSLine, RiArrowRightSLine, RiSkipLeftLine, RiSkipRightLine } from "react-icons/ri";

function Pagination(props) {
  const { pagination, setpagination, data, total } = props;
  // console.log(total);

  return (
    <div className="pagination" style={{ display: "flex" }}>
      <div style={{ display: "flex",alignSelf:"center" }}>
        <div>
          {data?.page} of {total} 
        </div>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;
        {/* <FormControl sx={{ m: 1,margin:0 }} size="small">
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          value={data}
          onChange={handleChange}
          style={{ backgroundColor: "#F4F4F4;"}}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
        </Select>
      </FormControl> */}
        {/* <span className="mt-2 d-block grey mx-2">1 – 3 of 3 items</span> */}
      </div>
      <div style={{ display: "flex" }}>
        {/* <FormControl sx={{ m: 1,margin:0,outline:'none' }} size="small">
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          value={data}
          onChange={handleChange}
          style={{ backgroundColor: "#F4F4F4;"}}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
        </Select>
      </FormControl> */}
        <div
          className={
            data?.page >= 3 ? "pagi_icon grey" : "pagi_icon light-grey"
          }
          onClick={() => (data?.page >= 3 ? setpagination(1) : "")}>
          <RiSkipLeftLine size={25} style={{ padding: "1px" }} />
        </div>
        <div
          className={
            data?.page !== 1 ? "pagi_icon grey" : "pagi_icon light-grey"
          }
          onClick={() =>
            data?.page !== 1 ? setpagination(pagination - 1) : ""
          }>
          <RiArrowLeftSLine size={25} style={{ padding: "1px" }} />
        </div>
        <div
          className={
            data?.page !== data?.totalPages
              ? "pagi_icon grey"
              : "pagi_icon light-grey"
          }
          onClick={() =>
            data?.page !== data?.totalPages ? setpagination(pagination + 1) : ""
          }>
          <RiArrowRightSLine size={25} style={{ padding: "1px" }} />
        </div>
        <div
          className={
            data?.page <= data?.totalPages - 2
              ? "pagi_icon grey"
              : "pagi_icon light-grey"
          }
          onClick={() =>
            data?.page <= data?.totalPages - 2
              ? setpagination(data?.totalPages)
              : ""
          }>
          <RiSkipRightLine size={25} style={{ padding: "1px" }} />
        </div>
      </div>
    </div>
  );
}

export default Pagination;
