import DashboardIcon from "@mui/icons-material/Dashboard";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import GroupsIcon from "@mui/icons-material/Groups";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
export const Data = [
  {
    title: "Dashboard",
    icon: <DashboardIcon />,
    link: "/dashboard",
  },
  {
    title: "Subscriber’s Details",
    icon: <SubscriptionsIcon />,
    link: "/subscriber-details/default",
  },
  {
    title: "User’s Summary",
    icon: <GroupsIcon />,
    link: "/user-summary",
  },
  {
    title: "User’s Alert History",
    icon: <NotificationsActiveIcon />,
    link: "/user-alert-history",
  },
  // {
  //   title: "Revenue Records",
  //   icon: <DynamicFeedIcon />,
  //   link: "/revenue-records",
  // },
];
