import React, { useEffect, useReducer, useState } from "react";
import "./user_summary.css";
// import { Search } from "semantic-ui-react";
import DownloadIcon from "../image/download_icon.svg";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import { DataGrid, gridClasses } from "@mui/x-data-grid";
// import { alpha, styled } from "@mui/material/styles";
import { IoMdSearch } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { GetUsers } from "../../redux/async-actions/Users";
import { setIsLoading } from "../../redux/reducers/UserSlice"
import TableList from "../../component/TableList";
import Pagination from "../../component/Pagination";
import { UsersTable } from "./data.js";
import { Users_data } from "./data.js";
import Loader from "../../Layout/Loader/Loader";
import { GetUserSearch } from "../../redux/async-actions/UserSearch";
import FileSaver from "file-saver";
import { BASE_URL } from "../../config/index.js";

export default function User_Summary() {
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const [filteredRows, setFilteredRows] = useState([]);
  const dispatch = useDispatch();

  const [swap, setswap] = useState(false);
  const [pagination1, setpagination1] = useState(1);
  const isLoading = useSelector((state) => state?.users?.isLoading);
  const Users = useSelector((state) => state?.users?.usersdata);
  const UserSearch = useSelector((state) => state?.usersearch?.usersearchdata);
  // console.log(UserSearch);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [reducerValue1, forceUpdate1] = useReducer((x) => x + 1, 0);
  const [pagination, setpagination] = useState(
    Users?.usersdata?.users?.page || 1
  );
  // const onclickdownload = () => {
  //   let link = "http://localhost:3030/exportUser";
  //   FileSaver.saveAs(link, "users");
  // };
  const onclickdownload = () => {
    console.log('start');
    dispatch(setIsLoading(true));
    setTimeout(() => {
      try {
        let link = `${BASE_URL}/exportUser`;
        FileSaver.saveAs(link, "users");
      } catch (error) {
        console.error("Error during download:", error);
      } finally {
        console.log('end');
        setTimeout(() => {
          dispatch(setIsLoading(false));
        }, 10000);
      }
    }, 100);
  };

  const handleSortChange = (event) => {
    const selectedValue = event.target.value;

    setSortValue(selectedValue);
  };
  const handleFilterChange = (event) => {
    const selectedValue = event.target.value;

    setFilterValue(selectedValue);
  };

  useEffect(() => {
    var pag = `?page=${pagination}`;
    dispatch(GetUsers(pag));
  }, [dispatch, pagination]);
  useEffect(() => {
    var key = `?keyword=${searchValue}`;
    dispatch(GetUserSearch(key));
  }, [reducerValue]);
  useEffect(() => {
    var key = `?page=${pagination}${sortValue !== "default" ? `&orderby=${sortValue}` : ""
      }${filterValue !== "default" ? `&sort=${filterValue}` : ""}`;

    dispatch(GetUserSearch(key));
  }, [reducerValue1, pagination]);

  useEffect(() => {
    // Filter rows based on search value
    if (searchValue) {
      setTimeout(() => {
        forceUpdate();
      }, 300);
    } else {
      setFilteredRows(Users?.users);
    }
  }, [searchValue]);
  useEffect(() => {
    // Filter rows based on search value
    if (sortValue || filterValue) {
      setTimeout(() => {
        forceUpdate1();
        // console.log("forced");
      }, 300);
    } else {
      setFilteredRows(Users?.users);
    }
  }, [sortValue, filterValue]);
  useEffect(() => {
    setTimeout(() => {
      setFilteredRows(UserSearch?.users);
    }, 100);
  }, [UserSearch?.users]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-1">
          <div>
            <h2>User's Summary</h2>
          </div>
          <div className="searchbox">
            <div className="filterview1">
              <input
                type="text"
                placeholder="Search by Keywords"
                name="search"
                className="search"
                value={searchValue}
                autoComplete="off"
                onChange={(e) => setSearchValue(e.target.value)}
              />

              <IoMdSearch
                className="icon-search"
                size={35}
                style={{ fill: "#2b3b41", backgroundColor: "#fff" }}
              />
            </div>
            {/* <div className="filterview">
          <div className="button-border">
            <FilterAltIcon sx={{ color: "#AF1E3E" }} />
            <div>Filter</div>
          </div>

          <select className="selectview">
            <option value="fruit">Sort by</option>

            <option value="vegetable">A to Z</option>

            <option value="meat">Z to A</option>
          </select>
          <DownloadIcon sx={{ color: "#AF1E3E", fontSize: 30 }} />
        </div> */}
            <div className="filter-set">
              <div className="filter-by">
                <div style={{ whiteSpace: "nowrap" }}>Filter by</div>
                <div>
                  <select
                    className="selectview"
                    onChange={handleFilterChange}
                    value={filterValue}
                  >
                    <option selected value="default">
                      Default
                    </option>
                    <option value="firstName">Firstname</option>
                    <option value="lastName">LastName</option>
                    <option value="email">Email Id</option>
                    <option value="isActive">Active</option>
                  </select>
                </div>
              </div>
              <div className="sort-by">
                <div style={{ whiteSpace: "nowrap" }}>sort by</div>
                <div>
                  <select
                    className="selectview"
                    onChange={handleSortChange}
                    value={sortValue}
                  >
                    <option selected value="default">
                      Default
                    </option>
                    <option value="asc">A to Z</option>
                    <option value="dec">Z to A</option>
                  </select>
                </div>
              </div>{" "}
            </div>
            <div>

              <button className="download-button" onClick={onclickdownload}>
                <img src={DownloadIcon} alt="download-icon" />
              </button>
            </div>
          </div>
          <div className="listview">
            <TableList
              Head={UsersTable}
              list={filteredRows}
              datas={Users_data}
              pagination={swap ? pagination1 : pagination}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {!searchValue && (
                <p style={{ float: "right", margin: "15px 50px 0 20px" }}>Users Count: {Users?.totalUsers
                }</p>)}
              {!searchValue && (
                <Pagination
                  setpagination={swap ? setpagination1 : setpagination}
                  pagination={swap ? pagination1 : pagination}
                  total={Users?.totalPages}
                  data={Users}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
