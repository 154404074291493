import { MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";

export const UsersTable = [
  { head: "S.No" },
  { head: "FirstName" },
  { head: "LastName" },
  { head: "Email Id" },
  { head: "Mobile.No" },
  { head: "PinCode" },
  { head: "Active" },
  { head: "Edit" }
];
export const Users_data = [
  { name: "firstName", type: "text" },
  { name: "lastName", type: "text" },
  { name: "email", type: "email" },
  { name: "phone", type: "text" },
  { name: "pinCode", type: "text" },
  { name: "isActive", type: "boolean" },
  {
    name: "edituser", code:
      <Link to={"/view"} className="pink me-2">
        <MdEdit />
      </Link>
  }
];