import { createSlice } from "@reduxjs/toolkit";
import { GetSubscriptions } from "../async-actions/Subscriptions";

const initialState = {
  status: "",
  isLoading: false,
  subscribers: [],
};

const SubscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetSubscriptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetSubscriptions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subscribers = action.payload;
        state.status = action.payload.status;
      });
  },
});

export const { setIsLoading } = SubscriptionsSlice.actions;
export default SubscriptionsSlice.reducer;
