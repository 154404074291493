import axios from "axios";
import { Signout } from "./async-actions/Auth";
const SetupInterceptors = (navigate, dispatch) => {
  axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      // Do something with response error
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate(
            "/login",
            { state: { pathname: "expired" } },
            { replace: true }
          );
        }
      }
      return Promise.reject(error);
    }
  );
};

export default SetupInterceptors;
