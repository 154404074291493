import { createSlice } from "@reduxjs/toolkit";
import { GetSubscriptionSearch } from "../async-actions/SubscriptionSearch";

const initialState = {
  status: "",
  isLoading:false,
  subscriptionsearchdata: [],
};

const SubscriptionSearchSlice = createSlice({
  name: "subscriptionsearch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(GetSubscriptionSearch.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(GetSubscriptionSearch.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subscriptionsearchdata = action.payload;
      // console.log(action.payload);
      state.status = action.payload.status;
    });
  },
});

export default SubscriptionSearchSlice.reducer;
