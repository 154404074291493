import React, { useEffect, useRef, useState } from "react";
import "./header.css";
import image from "../../screen/image/sabre_logo.png";
// import { Search } from "semantic-ui-react";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import { IoMdSearch } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

  



export default function Header({ showSidebar }) {
  const Profile = useSelector((state)=>state?.auth?.data);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
 



  return (
    <div className="header" ref={dropdownRef}>
      <RxHamburgerMenu className="ham-icon cursur" onClick={showSidebar} />
      <div className="logoview">
        <img src={image} alt="logo" />
      </div>
      <div className="titleview">
        <p>Welcome back, {localStorage.getItem("sabre-user")} </p>
      </div>
     {/*  <div className="searchview">
        <Search
          className="search"
          size="big"
          //loading={loading}
          placeholder="Search..."
          //   onResultSelect={(e, data) =>
          //   //  dispatch({ type: "UPDATE_SELECTION", selection: data.result.title })
          //   }
          // onSearchChange={handleSearchChange}
          //  results={results}
          //  value={value}
        /> 
        <input
          type="text"
          placeholder="Search"
          name="search"
          className="search"
        />
        <IoMdSearch
          className="icon-search"
          size={35}
          style={{ fill: "#fff", backgroundColor: "#2b3b41" }}
        /></div> 
      */}
            <div className="accountview">

       <AccountCircleIcon
            className="icons"
            fontSize="80px"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="profile-box">
              <ul>
                <li><NavLink to="/changepassword" className="link" onClick={closeDropdown}> Change Password</NavLink></li>
              </ul>
            </div>
          )}
                      </div>

      {/* <div className="user" style={{
            color: "#fff",
            
            margin: "auto"}}>{Profile?.email}</div> */}
    </div>
  );
}
