import { createSlice } from "@reduxjs/toolkit";
import { GetUserById } from "../async-actions/GetUserById";

const initialState = {
  status: "",
  isLoading:false,
  userbyiddata: [],
};

const UserByIdSlice = createSlice({
  name: "userbyid",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(GetUserById.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(GetUserById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userbyiddata = action.payload;
      // console.log(action.payload);
      state.status = action.payload.status;
    });
  },
});

export default UserByIdSlice.reducer;
