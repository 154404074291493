import React, { useState, useEffect, useCallback } from "react";
import "./login.css";
import loginimage from "../image/loginimage1.webp";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../redux/async-actions/Auth";
import { useNavigate } from "react-router-dom";
import { BsKey } from "react-icons/bs";

export default function Login(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const access = useSelector((state) => state.auth);
  const [errmsg, seterrmsg] = useState("");
  const [showLogin, setShowLogin] = useState(true); // State to show/hide login form
  const [showForgotPassword, setShowForgotPassword] = useState(false); // State to show/hide forgot password form
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState(""); // State for forgot password email
  const { isLoading, isSuccess, data, errorMessage } = useSelector((state) => state.auth);
  // console.log (data);

  const [credentials, setinputtext] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const [warnemail, setwarnemail] = useState(false);
  const [warnpassword, setwarnpassword] = useState(false);

  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [type, settype] = useState(false);

  const inputEvent = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setinputtext((lastValue) => {
      return {
        ...lastValue,
        [name]: value,
      };
    });
  };

  const submitForm = useCallback(async (e) => {
    e.preventDefault();
    setwarnemail(false);
    setwarnpassword(false);
    if (credentials.email === "") {
      setwarnemail(true);
    } else if (credentials.password === "") {
      setwarnpassword(true);
    } else {
      try {
        // Dispatch the login action
        await dispatch(login(credentials));



      } catch (error) {
        // Handle login error (e.g., display an error message)
        seterrmsg("Login Rejected");
        // console.log(data);

        // Clear the error message after 5 seconds
        setTimeout(() => {
          seterrmsg("");
        }, 5000);
      }
    }
  }, [credentials, dispatch, navigate]);
  // ... Existing code ...
  useEffect(() => {
    if (!data.status) {

      seterrmsg(data.message);

    }

  }, [data]);


  // ... Rest of the code ...


  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
      settype(true);
    } else {
      setpassword("password");
      seteye(true);
      settype(false);
    }
  };

  const handleForgotPasswordClick = () => {
    setShowLogin(false);
    setShowForgotPassword(true);
  };
  const handleForgotPasswordEmailChange = (event) => {
    setForgotPasswordEmail(event.target.value);
  };
  const handleBackToLoginClick = () => {
    setShowLogin(true);
    setShowForgotPassword(false);
  };
  useEffect(() => {
    if (access?.data?.token) {
      navigate("/dashboard", { replace: true });
    }
    if (errmsg) {
      const timer = setTimeout(() => {
        seterrmsg("");
      }, 5000);

      // Clear the timer and update the screen
      return () => {
        clearTimeout(timer);
        setShowLogin(true);
      };
    }
  }, [errmsg, access]);
  useEffect(() => { }, [submitForm]);

  return (
    <>
      <div className={"login-main"}>
        <div className="card">
          <div className="form">
            <div className={`right-side  ${showLogin ? "" : "hidden"}`}>
              <div className="text">
                <h3>LOGIN</h3>
              </div>

              <form onSubmit={submitForm}>
                <div className="input-text">
                  <input
                    type="email"
                    className={` ${warnemail ? "warning" : ""}`}
                    placeholder="Email id "
                    value={credentials.email}
                    onChange={inputEvent}
                    autoFocus
                    name="email"
                  />
                  <i className="fa fa-envelope-o"></i>
                  {warnemail && <p className="error">Enter Email Id</p>}
                </div>
                <div className="input-text">
                  <input
                    type={password}
                    className={` ${warnpassword ? "warning" : ""} ${type ? "type_password" : ""
                      }`}
                    placeholder="Password "
                    value={credentials.password}
                    onChange={inputEvent}
                    name="password"
                  />

                  <BsKey
                    style={{
                      position: "absolute",
                      left: "12px",
                      top: "15px",
                      cursor: "pointer",
                    }}
                  />
                  <i
                    onClick={Eye}
                    className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                  ></i>
                  {warnpassword && (
                    <p className="error">Enter Password </p>
                  )}
                </div>
                <div className="forgot">
                  <div style={{ display: "flex" }}>
                    <label className="container-box">
                      Remember me
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <p>
                    {" "}
                    <a href="#" onClick={handleForgotPasswordClick}>
                      Forgot Password?
                    </a>
                  </p>
                </div>
                <div className="input-text">
                  {errmsg && (
                    <p className={`error ${errmsg ? "animated" : ""}`}>
                      {errmsg}
                    </p>
                  )}
                </div>
                <div className="buttons">
                  <button type="submit">Login</button>
                </div>
              </form>
            </div>
            <div className={`right-side ${showForgotPassword ? "" : "hidden"}`}>
              <div className="text">
                <h3>Forgot Password</h3>

                <p>Please enter your email address to reset your password:</p>
              </div>
              <form>
                <div className="input-text">
                  <input
                    type="text"
                    autoFocus
                    placeholder="Email address"
                    value={forgotPasswordEmail}
                    onChange={handleForgotPasswordEmailChange}
                  />
                </div>
                <div className="buttons">
                  <button type="submit">Reset Password</button>
                </div>
              </form>
              <div className="back-to-login">
                <button onClick={handleBackToLoginClick}>Back to Login</button></div>
            </div>

            <div className="left-side">
              <img src={loginimage} alt="login" />;
            </div>
          </div>
        </div>
      </div>


    </>
  );
}
