import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { LOGIN_URL } from "../../config";

// Create the async thunk
export const login = createAsyncThunk(
  "auth/login",
  async (credentials, thunkAPI) => {
    // Perform your login logic here (e.g., API call)
    const response = await fetch(LOGIN_URL , {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    });
    const data = await response.json();
    // console.log(data);

    if (!response.status) {
      // Handle error case
      

      return thunkAPI.rejectWithValue(data);
    }

    // Return the data received from the server
    return data;
  }
);
export const Signout = createAction("signout");
