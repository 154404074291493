import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { updateAlerts } from "../../config";

// Create the async thunk
export const updateAlert = createAsyncThunk(
  "updatealert",
  async (ids, thunkAPI) => {
    // Perform your login logic here (e.g., API call)
    const response = await fetch(updateAlerts , {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,

      },
      body: JSON.stringify(ids),
    });
    const data = await response.json();
    // console.log(data);
    if (!response.ok) {
      // Handle error case
      
      return thunkAPI.rejectWithValue(data.error);
    }

    // Return the data received from the server
    return data;
  }
);
