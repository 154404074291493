// export const BASE_URL = "http://localhost:3030";
export const BASE_URL = "https://dashboard.sabreapp.com:3000";
// export const BASE_URL = "http://localhost:3000";
// export const BASE_URL = "http://18.212.29.197:3000";
// export const BASE_URL = "http://0.0.0.0:3030";

export const LOGIN_URL = `${BASE_URL}/login`;

// Get Api's Url
export const getDashboardDetails = `${BASE_URL}/dashboard`;
export const postChangePassword = `${BASE_URL}/change-password`;
export const getRecentAlerts = `${BASE_URL}/recent_alerts`;
export const getActiveSubscriptions = `${BASE_URL}/ActiveSubscriptions`;
export const getAlertsCount = `${BASE_URL}/AlertsCount`;
export const updateAlerts = `${BASE_URL}/deleteAlerts`;
export const getUsers = `${BASE_URL}/allUsers`;
export const getUserById = `${BASE_URL}/getUser`;
export const updateUserById = `${BASE_URL}/editUser`;
export const getUserCounts = `${BASE_URL}/usercount`;
export const getSubscriptions = `${BASE_URL}/allSubscriptions`;
export const getSubscriptionsCount = `${BASE_URL}/subscriptionsCount`;
export const getAlerts = `${BASE_URL}/allAlerts`;


