import React, { useEffect, useRef, useState } from "react";
import "./sidebar.css";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { Data } from "./data";
import Header from "../Header";
import { useDispatch, useSelector } from "react-redux";
import image from "../../screen/image/sabre-logo-footer.png";

import { IoMdLogOut } from "react-icons/io";
// import { styled } from "styled-components";
import styled from "styled-components";
import { Signout } from "../../redux/async-actions/Auth";
import Loader from "../Loader/Loader";

const SidebarNav = styled.nav`
  width: ${({ shotbar }) =>
    shotbar
      ? ({ sidebar }) => (sidebar ? "18vw" : "00px")
      : ({ sidebar }) => (sidebar ? "18vw" : "0px")};
      min-width:${({ shotbar }) =>
    shotbar
      ? ({ sidebar }) => (sidebar ? "210px" : "0px")
      : ({ sidebar }) => (sidebar ? "210px" : "0px")};

  // left: ${({ shortbar }) => (shortbar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
  overflow-y: auto;
  overflow-y: auto;
`;


export default function SideBar({ children }) {

  const dispatch = useDispatch();
  const [sidebar, setSidebar] = useState(true);
  const [shotbar, setshotbar] = useState(true);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const Details = useSelector((state) => state?.dashboarddetails);
  const mainRef = useRef(null);


  const showSidebar = () => setSidebar(!sidebar);
  const Logout = () => {
    //     localStorage.setItem("userToken", "");
    // navigate("/login", { replace: true });
    dispatch(Signout());
  };


  const navigate = useNavigate();
  // const { isLoading, isSuccess, data, } = useSelector((state) => state.auth);

  let autoResize = () => {
    if (window.innerWidth <= 768) {
      setSidebar(false);
      setshotbar(false);
    } else {
      setSidebar(true);
      setshotbar(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", autoResize);
    autoResize();
    // if(localStorage.getItem("userToken")===""){
    //   navigate("/login", { replace: true });
    // }
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [navigate]);
  const scrollToTop = () => {
    // if (mainRef.current) {
    //   mainRef.current.scrollTop = 0;
    // }
    window.scrollTo(0, 0); // Scroll the whole window to the top
  };

  // const x = window.outerWidth < 769 ? false : true;
  // const [isOpen, setIsOpen] = useState(true && x);


  // const toggleSidebar = () => {
  //   setIsOpen(!isOpen);
  // };
  //  const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      {loading ? <Loader /> :
        <div className="container">
          <Header showSidebar={showSidebar} />

          <div className="body">
            {/* <button onClick={toggleSidebar} className="openbtn">
          {isOpen ? "x" : "☰"}
        </button> */}
            {/* {isOpen && ( */}
            <SidebarNav className="sidebar" sidebar={sidebar} shotbar={shotbar}>
              <div>
                {Data.map((item, index) => (
                  <NavLink
                    to={item.link}
                    onClick={scrollToTop}
                    key={index}
                    className="link"
                    activeclassname="active">
                    <div className="icon">{item.icon}</div>
                    <div style={{ display: "block" }} className="link_text">
                      {item.title}
                    </div>
                  </NavLink>
                ))}
              </div>


              <div>
                <div className="logout">
                  <NavLink to="/login" className="link">
                    <div className="icon">
                      <IoMdLogOut />
                    </div>
                    <div
                      style={{ display: "block" }}
                      className="link_text"
                      onClick={Logout}>
                      Logout
                    </div>
                  </NavLink>
                </div>
                <div className="copy">
                  Copyright © 2005-2024 <br />
                  SABRE - Security Equipment Corp.
                </div>
                <img src={image} alt="logo-footer" className="footer-logo" />
              </div>
            </SidebarNav>
            {/* )} */}

            <main ref={mainRef}>
              <Outlet />
            </main>
          </div>
        </div>
      }
    </>
  );
}
