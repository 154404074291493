import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./screen/login/Login";
import SideBar from "./Layout/sidebar";
import "../src/assets/style.css";
import { Switch } from 'react-router-dom';

import "./App.css";
import Dashboard from "./screen/Dashboard";
import Subscriber from "./screen/Subscriber_Details";
import UserAlertHistory from "./screen/User_Alert_History";
import UserSummary from "./screen/User_Summary";
import RevenueRecords from "./screen/Revenue_Records";
import { useDispatch } from "react-redux";
import { useState } from "react";
import SetupInterceptors from "./redux/SetupInterceptors";
import ChangePassword from "./screen/Change_Password";
import { useSelector } from "react-redux"; // Import the useSelector hook
import ViewUsers from "./screen/Dashboard/ViewUsers";


// function Main() {
//   return (
//     <div className="App">
//       <SideBar>
//         <Routes>
//           <Route path="/" element={<Dashboard />} />
//           <Route path="/dashboard" element={<Dashboard />} />
//           <Route path="/subscriber-details" element={<Subscriber />} />
//           <Route path="/user-alert-history" element={<UserAlertHistory />} />
//           <Route path="/user-summary" element={<UserSummary />} />
//           <Route path="/revenue-records" element={<RevenueRecords />} />
//         </Routes>
//       </SideBar>
//     </div>
//   );
// }

function NavigateFunctionComponent(props) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [ran, setRan] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth); // Replace 'isLoggedIn' with your actual Redux state that tracks the login status

  if (!ran) {
    SetupInterceptors(navigate, dispatch);
    setRan(true);
  }
  return <></>;
}

function App() {
  return (
    <div className="App">
      <NavigateFunctionComponent />
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/" element={<SideBar />}>
          <Route index element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
         
         <Route path="/user-summary/:id/view" element={<ViewUsers />} />


          <Route path="/changepassword" element={<ChangePassword />}/>
          <Route path="/subscriber-details/:subscriberstatus" element={<Subscriber />} />
          <Route path="/user-alert-history" element={<UserAlertHistory />} />
          <Route path="/user-summary" element={<UserSummary />} />
          <Route path="/revenue-records" element={<RevenueRecords />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
