export const SubscribersTable = [
  { head: "S.No" },
  { head: "User Name" },
  {head:"Mobile.No"},
  { head: "Environment" },
  { head: "Product Id" },
  { head: "Platform" },
  { head: "Purchased Date" },
  { head: "Expiry Date" },
  { head: "Status" },
];
 export const Subscribers_data = [
    { name: "joinedData",value:"firstName",value2:"lastName" },
    { name: "joinedData",value:"phone" },
    { name: "environment" },
    { name: "productId" },
    { name: "platform" },
    { name: "purchasedDateMs" },
    { name: "expiresDateMs" },
    { name: "isCancelled" },
  ];