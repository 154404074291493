import axios from "axios";

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    config.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    };
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error

    return Promise.reject(error);
  }
);
export default axios;
