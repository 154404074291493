import { createAsyncThunk } from "@reduxjs/toolkit";
import { getActiveSubscriptions } from "../../config";
import axios from "../axios";


export const GetActiveSubscriptions = createAsyncThunk(
  `/activesubscriptions`,
  async (pagination) => {
    const response = await axios.get(`${getActiveSubscriptions}${pagination}`);
    //   console.log(response?.data);
    return await response?.data;
  }
);
