import { createAsyncThunk } from "@reduxjs/toolkit";
import {  getUsers } from "../../config";
import axios from "../axios";

export const GetUsers = createAsyncThunk(
  `/users`,
  async (pagination) => {
    const response = await axios.get(`${getUsers}${pagination}`);
    //   console.log(response?.data);
    return await response?.data;
  }
);
