import { createSlice } from "@reduxjs/toolkit";
import { changepassword } from "../async-actions/ChangePassword";

const initialState = {
  data: {},
  isLoading: false,
  isSuccess: false,
  errorMessage: [],
};

export const ChangePasswordSlice = createSlice({
  name: "changepasswords",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changepassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changepassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // console.log("Change successssss");
        state.data = action.payload; // Updated field name

        // console.log(action.payload);

      })
      .addCase(changepassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.data =action.payload;
        state.errorMessage = action.error;
        // console.log(action);
        // console.log(state.data);
        // console.log("rejected");

      });
  },
});

export default ChangePasswordSlice.reducer;
