import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "../reducers/AuthSlice";
import SubscriptionsCountSlice from "../reducers/SubscriptionsCountSlice";
import SubscriptionsSlice from "../reducers/SubscriptionsSlice";
import RecentAlertsSlice from "../reducers/RecentAlertsSlice";
import DashboardSlice from "../reducers/DashboardSlice";
import ActiveSubscriptionsSlice from "../reducers/ActiveSubscriptionsSlice";

import UserSlice from "../reducers/UserSlice";
import AlertSlice from "../reducers/AlertSlice";
import ChangePasswordSlice from "../reducers/ChangePasswordSlice";
import AlertsCountSlice from "../reducers/AlertsCountSlice";
import AlertSearchSlice from "../reducers/AlertSearchSlice";
import UserSearchSlice from "../reducers/UserSearchSlice";
import SubscriptionSearchSlice from "../reducers/SubscriptionSearchSlice";
import UserByIdSlice from "../reducers/UserByIdSlice";
import  UpdateUserSlice  from "../reducers/UpdateUserSlice";
import UpdateAlertSlice from "../reducers/UpdateAlertSlice";

export default configureStore({
  reducer: {
    auth: AuthReducer,
    subscriptionscount: SubscriptionsCountSlice,
    subscriptions: SubscriptionsSlice,
    dashboarddetails: DashboardSlice,
    users: UserSlice,
    alerts:AlertSlice,
    changepasswords:ChangePasswordSlice,
    recentalerts:RecentAlertsSlice,
    activesubscriptions: ActiveSubscriptionsSlice,
    alertscount: AlertsCountSlice,
    alertsearch:AlertSearchSlice,
    usersearch:UserSearchSlice,
    subscriptionsearch:SubscriptionSearchSlice,
    userbyid:UserByIdSlice,
    updateuser:UpdateUserSlice,
    updatealert:UpdateAlertSlice
  }
});
