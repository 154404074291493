import { createSlice } from "@reduxjs/toolkit";
import { GetAlertsCount } from "../async-actions/AlertCounts";

const initialState = {
  status: "",
  isLoading:false,
  alertscount: [],
};

const AlertsCountSlice = createSlice({
  name: "recentalerts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(GetAlertsCount.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(GetAlertsCount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.alertscount = action.payload;
      state.status = action.payload.status;
    });
  },
});

export default AlertsCountSlice.reducer;
