import React, { useEffect, useReducer, useState } from "react";
import "./subscriber.css";
// import { Search } from "semantic-ui-react";
import DownloadIcon from "../image/download_icon.svg";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import { DataGrid, gridClasses } from "@mui/x-data-grid";
// import { alpha, styled } from "@mui/material/styles";
import { IoMdSearch } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { GetSubscriptions } from "../../redux/async-actions/Subscriptions";
import { setIsLoading } from "../../redux/reducers/SubscriptionsSlice"
import Pagination from "../../component/Pagination";
import TableList from "../../component/TableList";
import { SubscribersTable, Subscribers_data } from "./data";
import Loader from "../../Layout/Loader/Loader";
import { GetSubscriptionSearch } from "../../redux/async-actions/SubscriptionSearch";
import FileSaver from "file-saver";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../config/index.js";

export default function Subscriber_Details() {
  const { subscriberstatus } = useParams();
  // console.log(subscriberstatus);
  const dispatch = useDispatch();
  const [swap, setswap] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [pagination1, setpagination1] = useState(1);
  const isLoading = useSelector((state) => state?.subscriptions?.isLoading);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [sortValue, setSortValue] = useState("");
  const [statusValue, setStatusValue] = useState(
    subscriberstatus !== "active" ? "default" : "active"
  );
  const [filterValue, setFilterValue] = useState("");

  const [filterSet, setFilterSet] = useState();
  const Subscribers = useSelector((state) => state?.subscriptions?.subscribers);
  const [reducerValue1, forceUpdate1] = useReducer((x) => x + 1, 0);

  const SubscriptionSearch = useSelector(
    (state) => state?.subscriptionsearch?.subscriptionsearchdata
  );
  // console.log(SubscriptionSearch);

  const [pagination, setpagination] = useState(
    Subscribers?.subscriptions?.users?.page || 1
  );
  // const onclickdownload = () => {
  //   console.log('start');
  //   dispatch(setIsLoading(true));
  //   if (statusValue === "default") {
  //     let link = "http://localhost:3030/exportSubscription";

  //     FileSaver.saveAs(link, "subscribers");
  //     console.log('end');
  //     dispatch(setIsLoading(false));
  //   } else {
  //     let link = `http://localhost:3030/exportSubscription?status=${statusValue}`;
  //     if (statusValue === "active") {
  //       FileSaver.saveAs(link, "subscribersactive");
  //     } else {
  //       FileSaver.saveAs(link, "subscribersInactive");
  //     }
  //   }
  // };
  const onclickdownload = () => {
    console.log('start');
    dispatch(setIsLoading(true));
    setTimeout(() => {
      try {
        let link;
        if (statusValue === "default") {
          link = `${BASE_URL}/exportSubscription`;
          FileSaver.saveAs(link, "subscribers");
        } else {
          link = `${BASE_URL}/exportSubscription?status=${statusValue}`;
          if (statusValue === "active") {
            FileSaver.saveAs(link, "subscribersactive");
          } else {
            FileSaver.saveAs(link, "subscribersInactive");
          }
        }
      } catch (error) {
        console.error("Error during download:", error);
      } finally {
        console.log('end');
        setTimeout(() => {
          dispatch(setIsLoading(false));
        }, 10000);
      }
    }, 100);
  };
  const handleSortChange = (event) => {
    const selectedValue = event.target.value;

    setSortValue(selectedValue);
  };
  const handleStatusChange = (event) => {
    const selectedValue = event.target.value;

    setStatusValue(selectedValue);
  };
  const handleFilterChange = (event) => {
    const selectedValue = event.target.value;

    setFilterValue(selectedValue);
  };
  useEffect(() => {
    if (subscriberstatus === "active") {
      setFilterSet(true);
    }
    var pag = `?page=${pagination}${subscriberstatus === "active" ? "&status=active" : ""
      }`;
    dispatch(GetSubscriptions(pag));
  }, [dispatch, pagination]);
  useEffect(() => {
    var key = `?keyword=${searchValue}${statusValue !== "default" ? `&status=${statusValue}` : ""
      }`;
    dispatch(GetSubscriptionSearch(key));
  }, [reducerValue]);
  useEffect(() => {
    var key = `?page=${pagination}${sortValue !== "default" ? `&orderBy=${sortValue}` : ""
      }${statusValue !== "default" ? `&status=${statusValue}` : ""}${filterValue !== "default" ? `&sortBy=${filterValue}` : ""}`;

    dispatch(GetSubscriptionSearch(key));
  }, [reducerValue1, pagination]);
  useEffect(() => {
    if (searchValue) {
      setTimeout(() => {
        forceUpdate();
      }, 300);
    } else {
      setFilteredRows(Subscribers?.results);
    }
  }, [searchValue]);
  useEffect(() => {
    // Filter rows based on search value
    if (sortValue || statusValue || filterValue) {
      setTimeout(() => {
        setpagination(1);
        forceUpdate1();
        // console.log("forced");
      }, 300);
    } else {
      setFilteredRows(Subscribers?.results);
    }
  }, [sortValue, statusValue, filterValue]);
  useEffect(() => {
    setTimeout(() => {
      setFilteredRows(SubscriptionSearch?.results);
    }, 100);
  }, [SubscriptionSearch?.results]);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-1">
          <div>
            <h2>Subscriptions Details</h2>
          </div>
          <div className="searchbox">
            <div className="filterview1">
              {/* <Search
            className="search"
            size="big"
            //loading={loading}
            placeholder="Search..."
            //   onResultSelect={(e, data) =>
            //   //  dispatch({ type: "UPDATE_SELECTION", selection: data.result.title })
            //   }
            // onSearchChange={handleSearchChange}
            //  results={results}
            //  value={value}
          /> */}
              <input
                type="text"
                placeholder="Search by Keywords"
                name="search"
                className="search"
                value={searchValue}
                autoComplete="off"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <IoMdSearch
                className="icon-search"
                size={35}
                style={{ fill: "#2b3b41", backgroundColor: "#fff" }}
              />
            </div>
            <div className="filter-set">
              <div className="filter-by">
                <div style={{ whiteSpace: "nowrap" }}>Status </div>
                <div>
                  <select
                    className="selectview"
                    onChange={handleStatusChange}
                    value={statusValue}
                  >
                    <option value="default">Default</option>
                    <option value="active">active</option>

                    <option value="inActive">inActive</option>
                  </select>
                </div>
              </div>
              <div className="sort-by">
                <div style={{ whiteSpace: "nowrap" }}>sort by</div>
                <div>
                  <select
                    className="selectview"
                    onChange={handleSortChange}
                    value={sortValue}
                  >
                    <option selected value="default">
                      Default
                    </option>
                    <option value="asc">A to Z</option>
                    <option value="desc">Z to A</option>
                  </select>
                </div>
              </div>
              <div className="sort-by">
                <div style={{ whiteSpace: "nowrap" }}>filter by</div>
                <div>
                  <select
                    className="selectview"
                    onChange={handleFilterChange}
                    value={filterValue}
                  >
                    <option selected value="default">
                      Default
                    </option>
                    <option value="name">Firstname</option>
                    <option value="lastName">LastName</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <button className="download-button" onClick={onclickdownload}>
                <img src={DownloadIcon} alt="download-icon" />
              </button>
            </div>
          </div>
          <div className="listview">
            <TableList
              Head={SubscribersTable}
              list={filteredRows}
              datas={Subscribers_data}
              pagination={swap ? pagination1 : pagination}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {!searchValue && (statusValue === "default" || filterValue === "default") && (
                <p style={{ float: "right", margin: "15px 50px 0 20px" }}>Subscribers Count: {Subscribers?.totalSubscriptionProductionCount
                }</p>)}
              {!searchValue && (statusValue !== "default" && filterValue !== "default") && (
                <p style={{ float: "right", margin: "15px 50px 0 20px" }}>{`${(statusValue === "active") ? "Active " : "Inactive "}`}Subscribers Count: {SubscriptionSearch?.totalSubscriptionProductionCount
                }</p>)}
              {!searchValue && (statusValue === "default" || filterValue === "default") && (
                <Pagination
                  setpagination={swap ? setpagination1 : setpagination}
                  pagination={swap ? pagination1 : pagination}
                  total={Subscribers?.totalPages}
                  data={Subscribers}
                />
              )}
              {!searchValue && (statusValue !== "default" && filterValue !== "default") && (
                <Pagination
                  setpagination={swap ? setpagination1 : setpagination}
                  pagination={swap ? pagination1 : pagination}
                  total={SubscriptionSearch?.totalPages}
                  data={SubscriptionSearch}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
