import { createSlice } from "@reduxjs/toolkit";
import { GetAlerts } from "../async-actions/Alert";

const initialState = {
  status: "",
  isLoading:false,
  alertsdata: [],
};

const AlertSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(GetAlerts.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(GetAlerts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.alertsdata = action.payload;
      // console.log(action.payload);
      state.status = action.payload.status;
    });
  },
});

export default AlertSlice.reducer;
