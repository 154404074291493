import { createSlice } from "@reduxjs/toolkit";
import { updateUser } from "../async-actions/UpdateUser";

export const UpdateUserSlice = createSlice({
  name: "updateuser",
  initialState: {
    data: {},
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        //  console.log("Change successssss");
        state.data = action.payload;
        // console.log(action.payload);

      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.data =action.payload;

        state.errorMessage = action.payload;
        // console.log(action);
        // console.log(state.data);
        // console.log("rejected");    
        })
     
  },
});

export default UpdateUserSlice.reducer;
