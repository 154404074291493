// import React, { useCallback, useEffect, useReducer, useState } from 'react';
// import { Users_data } from '../User_Summary/data';
// import "./ViewUsers.css";
// import { useDispatch, useSelector } from 'react-redux';
// import { GetUserById } from '../../redux/async-actions/GetUserById';
// import { updateUser } from '../../redux/async-actions/UpdateUser';
// import { MdArrowBack } from 'react-icons/md';
// import { Link } from 'react-router-dom';
// // ... (previous imports and code)

// function ViewUsers() {
//   const datas = Users_data;
//   console.log(datas)
//   const dispatch = useDispatch();

//   const UserById = useSelector((state) => state?.userbyid?.userbyiddata);
//   // console.log(UserById);
//   const userupdate = useSelector((state) => state.updateuser?.data);
//   // console.log(userupdate);

//   let id = window.location.pathname.split("/")[2];
//   // console.log(id);
//   const [changedData, setChangedData] = useState({ userId: id, });
//   const [changed, setChanged] = useState(false);
//   const [successMessage, setSuccessMessage] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");
//   const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);



//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setChangedData(prevData => ({
//       ...prevData,
//       [name]: value
//     }));
//     setChanged(true);
//   };

//   const handleSubmit = useCallback(async (e) => {
//     e.preventDefault();
//     //  console.log("Changed Data:", changedData);
//     const pinCodePattern = /^\d{4}$/;

//     if (!pinCodePattern.test(changedData.pinCode)) {
//       // picode is not four digits
//       // You can handle this error condition as needed
//       //  console.error("picode must be four digits.");
//       setErrorMessage("picode must be four digits.");

//       return;
//     }
//     try {
//       await dispatch(updateUser(changedData));
//       await forceUpdate();
//     } catch (error) {
//       // Error occurred during password change
//     }
//   }, [changedData, dispatch]);

//   useEffect(() => {
//     var key = `/:${id}`;
//     dispatch(GetUserById(key));
//   }, [dispatch, id]);
//   useEffect(() => {
//     if (userupdate) {
//       if (userupdate.message === "User updated successfully") {
//         // Password change successful
//         // console.log(userupdate.message);
//         // console.log("success");
//         setSuccessMessage(userupdate.message);
//       } else {
//         setErrorMessage(userupdate.message);
//         // console.log("user wrong");
//         // console.log(userupdate.message);
//       }
//       setTimeout(() => {
//         setErrorMessage("");
//         setSuccessMessage("");
//       }, 3000);
//     }

//   }, [reducerValue]);

//   return (
//     <div style={{ margin: "10px" }}>
//       <Link to={`/user-summary`}>
//         <MdArrowBack style={{ color: "#ae1c3d", margin: "0 0 10px" }} size={20} />
//       </Link>
//       <h2>User Details</h2>
//       <form className='user-data' onSubmit={handleSubmit}>
//         <div className="user-data-container">
//           {datas &&
//             datas.map((data, index) => {
//               // if (data.name === 'edituser' || data.name === 'isActive') {
//               //   return null;
//               // }
//               if (data.name === 'edituser') {
//                 return null;
//               }
//               if (data.name === 'isActive') {
//                 return (
//                   <div
//                     key={index}
//                     className={`user-datas ${index % 2 === 0 ? 'even' : 'odd'}`}
//                   >
//                     <label htmlFor={data.name}>{data.name}</label>
//                     <input
//                       type="checkbox"
//                       id={data.name}
//                       name={data.name}
//                       checked={changed ? changedData.isActive : UserById?.Users?.isActive || false}
//                       onChange={handleInputChange}
//                     />
//                   </div>
//                 );
//               }

//               return (
//                 <div
//                   key={index}
//                   className={`user-datas ${index % 2 === 0 ? 'even' : 'odd'}`}
//                 >
//                   <label htmlFor={data.name}>{data.name}</label>
//                   <input
//                     type={data.type}
//                     className='user-data'
//                     id={data.name}
//                     value={changed ? changedData[data.name] : UserById?.Users?.[data.name]}
//                     name={data.name}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               );
//             })}
//         </div>
//         <div>{successMessage && <p className='success-msg'>{successMessage}</p>}</div>
//         <div>
//           {errorMessage && <p className='err-msg'>{errorMessage}</p>}
//         </div>

//         <button type="submit">Submit</button>
//       </form>
//     </div>
//   );
// }

// export default ViewUsers;








import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Users_data } from '../User_Summary/data';
import "./ViewUsers.css";
import { useDispatch, useSelector } from 'react-redux';
import { GetUserById } from '../../redux/async-actions/GetUserById';
import { updateUser } from '../../redux/async-actions/UpdateUser';
import { MdArrowBack } from 'react-icons/md';
import { Link } from 'react-router-dom';

function ViewUsers() {
  const datas = Users_data;
  const dispatch = useDispatch();

  const UserById = useSelector((state) => state?.userbyid?.userbyiddata);
  const userupdate = useSelector((state) => state.updateuser?.data);

  let id = window.location.pathname.split("/")[2];
  const [changedData, setChangedData] = useState({ userId: id, });
  const [changed, setChanged] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setChangedData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value // Ensure isActive is updated correctly
    }));
    setChanged(true);
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setHasSubmitted(true);
    console.log(changedData)
    // Check if pinCode is present and only validate if it's available
    if (changedData.pinCode) {
      const pinCodePattern = /^\d{4}$/;
      if (!pinCodePattern.test(changedData.pinCode)) {
        setErrorMessage("Pincode must be four digits.");
        return;
      }
    }

    try {
      await dispatch(updateUser(changedData));
      setErrorMessage(""); // Clear any previous error messages
      forceUpdate(); // Trigger re-render after successful update
    } catch (error) {
      setErrorMessage("An error occurred while updating the user."); // Handle any API error
    }
  }, [changedData, dispatch]);

  useEffect(() => {
    var key = `/:${id}`;
    dispatch(GetUserById(key));
  }, [dispatch, id]);

  useEffect(() => {
    if (UserById?.Users) {
      setChangedData({
        userId: id,
        isActive: UserById?.Users?.isActive || false, // Initialize isActive from the user data
      });
    }
  }, [UserById, id]);

  useEffect(() => {
    if (hasSubmitted && userupdate) {
      if (userupdate.message === "User updated successfully") {
        setSuccessMessage(userupdate.message);
      } else {
        setErrorMessage(userupdate.message);
      }
      setTimeout(() => {
        setErrorMessage("");
        setSuccessMessage("");
      }, 3000);
    }
  }, [userupdate, hasSubmitted]);

  return (
    <div style={{ margin: "10px" }}>
      <Link to={`/user-summary`}>
        <MdArrowBack style={{ color: "#ae1c3d", margin: "0 0 10px" }} size={20} />
      </Link>
      <h2>User Details</h2>
      <form className='user-data' onSubmit={handleSubmit}>
        <div className="user-data-container">
          {datas &&
            datas.map((data, index) => {
              if (data.name === 'edituser') {
                return null;
              }

              // if (data.name === 'isActive') {
              //   return (
              //     <div
              //       key={index}
              //       className={`user-datas ${index % 2 === 0 ? 'even' : 'odd'}`}
              //     >
              //       <label htmlFor={data.name}>{data.name}</label>
              //       <input
              //         type="checkbox"
              //         id={data.name}
              //         name={data.name}
              //         checked={changed ? changedData.isActive : UserById?.Users?.isActive || false}
              //         onChange={handleInputChange}
              //       />
              //     </div>
              //   );
              // }
              if (data.name === 'isActive') {
                return (
                  <div
                    key={index}
                    className={`user-datas ${index % 2 === 0 ? 'even' : 'odd'}`}
                  >
                    <label htmlFor={data.name} className="checkbox-container">
                      <input
                        type="checkbox"
                        id={data.name}
                        name={data.name}
                        checked={changed ? changedData.isActive : UserById?.Users?.isActive || false}
                        onChange={handleInputChange}
                      />
                      <span className="checkbox"></span> {/* Custom checkbox */}
                      {data.name}
                    </label>
                  </div>
                );
              }

              return (
                <div
                  key={index}
                  className={`user-datas ${index % 2 === 0 ? 'even' : 'odd'}`}
                >
                  <label htmlFor={data.name}>{data.name}</label>
                  <input
                    type={data.type}
                    className='user-data'
                    id={data.name}
                    value={changed ? changedData[data.name] : UserById?.Users?.[data.name]}
                    name={data.name}
                    onChange={handleInputChange}
                  />
                </div>
              );
            })}
        </div>
        <div>{successMessage && <p className='success-msg'>{successMessage}</p>}</div>
        <div>{errorMessage && <p className='err-msg'>{errorMessage}</p>}</div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default ViewUsers;

