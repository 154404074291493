import { createSlice } from "@reduxjs/toolkit";
import { GetUsers } from "../async-actions/Users";

const initialState = {
  status: "",
  isLoading: false,
  usersdata: [],
};

const UsersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.usersdata = action.payload;
        state.status = action.payload.status;
      });
  },
});

export const { setIsLoading } = UsersSlice.actions;
export default UsersSlice.reducer;
