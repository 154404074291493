import { createSlice } from "@reduxjs/toolkit";
import { Signout, login } from "../async-actions/Auth";

export const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    data: {},
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        localStorage.setItem("userToken", payload.token);
        localStorage.setItem("sabre-user", payload.email);
        state.data = payload;
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;

        state.isSuccess = false;
        // console.log("rejected");
        state.errorMessage = payload.error;
        // console.log(payload);
      })
      .addCase(Signout, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.data={};
        localStorage.clear("userToken");
        localStorage.clear("sabre-user");

        localStorage.clear("count");
      });
  },
});

export default AuthSlice.reducer;
