import { createSlice } from "@reduxjs/toolkit";
import { GetRecentAlerts } from "../async-actions/RecentAlerts";

const initialState = {
  status: "",
  isLoading:false,
  recentalerts: [],
};

const RecentAlertsSlice = createSlice({
  name: "recentalerts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(GetRecentAlerts.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(GetRecentAlerts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.recentalerts = action.payload;
      state.status = action.payload.status;
    });
  },
});

export default RecentAlertsSlice.reducer;
