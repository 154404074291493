export const UsersAlertTable = [
  { head: "S.No" },
  { head: "User Name" },
  {head:"Mobile.No"},
  { head: "Trigger By Device" },
  { head: "Status" },
  { head: "Cancel By" },
];
export const Users_alert_data = [
  { name: "joinedData",value:"firstName",value2:"lastName" },
  { name: "joinedData",value:"phone" },
  { name: "isTriggerByDevice" },
  { name: "status" },
  { name: "canceledBy" },
];
