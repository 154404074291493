import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { postChangePassword } from "../../config";
import axios from "../axios";
// import { postChangePassword } from "../../config";

// Create the async thunk
export const changepassword = createAsyncThunk(
  "changepassword",
  async (passwords, thunkAPI) => {
    // Perform your login logic here (e.g., API call)
    const response = await fetch(postChangePassword , {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,

      },
      body: JSON.stringify(passwords),
    });
    const data = await response.json();
    // console.log(data);
    if (!response.ok) {
      // Handle error case

      return thunkAPI.rejectWithValue(data);
    }

    // Return the data received from the server
    return data;
  }
);
export const Signout = createAction("signout");

// export const changepassword = createAsyncThunk(
//     "/changepassword",
//     async (passwords,thunkAPI) => {
//       const response = await axios.post(postChangePassword, passwords);
      
//       console.log(response);
//       console.log("response1");

//       console.log(response.json());

//       if (!response.ok) {
//         // Handle error case
//         console.log(response.error);
//         return thunkAPI.rejectWithValue(response.error);

//       }
//       return response.data;
      
//     }
    
//   );
  