import React, { useEffect, useState } from "react";
import "./revenue_records.css";
// import { Search } from "semantic-ui-react";
import DownloadIcon from "@mui/icons-material/Download";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import { DataGrid, gridClasses } from "@mui/x-data-grid";
// import { alpha, styled } from "@mui/material/styles";
import { IoMdSearch } from "react-icons/io";
import { UsersTable, Users_data } from "../User_Summary/data";
import TableList from "../../component/TableList";
import Pagination from "../../component/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { GetUsers } from "../../redux/async-actions/Users";


export default function Revenue_Records() {
    const [searchValue, setSearchValue] = useState("");
    const [filteredRows, setFilteredRows] = useState([]);
    const dispatch = useDispatch();

    const [swap, setswap] = useState(false);
    const [pagination1, setpagination1] = useState(1);

    const Users = useSelector((state) => state?.users?.usersdata);
    const [pagination, setpagination] = useState(
      Users?.usersdata?.users?.page || 1
    );

    useEffect(() => {
      var pag = `?page=${pagination}`;
      dispatch(GetUsers(pag));
    }, [pagination]);

    useEffect(() => {
      // Filter rows based on search value
      if (searchValue) {
        const filteredRows = Users?.users.filter((user) => {
          // Modify this condition to match the fields you want to search in
          return (
            (user.firstName &&
              user.firstName
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (user.email &&
              user.email.toLowerCase().includes(searchValue.toLowerCase()))
          );
        });
        setFilteredRows(filteredRows);
      } else {
        setFilteredRows([]);
      }
    }, [searchValue, Users?.users]);

  return (
    <div className="container-1">
      <div>
        <h2>Revenue Records</h2>
      </div>
      <div className="searchbox">
        <div className="filterview1">
          {/* <Search
            className="search"
            size="big"
            //loading={loading}
            placeholder="Search..."
            //   onResultSelect={(e, data) =>
            //   //  dispatch({ type: "UPDATE_SELECTION", selection: data.result.title })
            //   }
            // onSearchChange={handleSearchChange}
            //  results={results}
            //  value={value}
          /> */}
          <input
            type="text"
            placeholder="Search by Keywords"
            name="search"
            className="search"
          />
          <IoMdSearch
            className="icon-search"
            size={35}
            style={{ fill: "#2b3b41", backgroundColor: "#fff" }}
          />
        </div>
        <div className="filterview">
          <div className="button-border">
            <FilterAltIcon sx={{ color: "#AF1E3E" }} />
            <div>Filter</div>
          </div>

          <select className="selectview">
            <option value="fruit">Sort by</option>

            <option value="vegetable">A to Z</option>

            <option value="meat">Z to A</option>
          </select>
          <DownloadIcon sx={{ color: "#AF1E3E", fontSize: 30 }} />
        </div>
      </div>
      <div className="listview">
        <TableList
          Head={UsersTable}
          list={searchValue ? filteredRows : Users?.users}
          datas={Users_data}
          pagination={swap ? pagination1 : pagination}
        />
        <Pagination
          setpagination={swap ? setpagination1 : setpagination}
          pagination={swap ? pagination1 : pagination}
          total={Users?.totalPages}
          data={Users}
        />
      </div>
    </div>
  );
}
